import React, { useEffect, useState } from 'react';
import './Kollektion.css';
import Item from '../Item/Item';

export const Kollektion = () => {
    const [newCollection, setNewCollection] = useState([]);
    const [loading, setLoading] = useState(true);

    // useEffect(() => {
    //     const fetchProducts = async () => {
    //         try {
    //             const response = await fetch('https://skosiden.dk/backend/?route=allproducts');
    //             if (!response.ok) {
    //                 throw new Error('Network response was not ok');
    //             }
    //             const data = await response.json();

    //             if (data.success && Array.isArray(data.products)) {
    //                 // Parse sizes and images for each product
    //                 const formattedProducts = data.products.map(product => ({
    //                     ...product,
    //                     images: JSON.parse(product.images),
    //                     sizes: JSON.parse(product.sizes)
    //                 }));
    //                 setNewCollection(formattedProducts);
    //             } else {
    //                 console.error('Failed to fetch products or no products found');
    //             }
    //         } catch (error) {
    //             console.error('Error fetching products:', error);
    //         } finally {
    //             setLoading(false);
    //         }
    //     };

    //     fetchProducts();
    // }, []);

    
    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await fetch('https://skosiden.dk/backend/?route=allproducts');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
    
                if (data.success && Array.isArray(data.products)) {
                    // Parse sizes and images for each product
                    const formattedProducts = data.products
                        .map(product => ({
                            ...product,
                            images: JSON.parse(product.images),
                            sizes: JSON.parse(product.sizes)
                        }))
                        .filter(product => 
                            // Check if any size has quantity > 0
                            product.sizes.some(size => size.quantity > 0)
                        );
    
                    setNewCollection(formattedProducts);
                } else {
                    console.error('Failed to fetch products or no products found');
                }
            } catch (error) {
                console.error('Error fetching products:', error);
            } finally {
                setLoading(false);
            }
        };
    
        fetchProducts();
    }, []);
    

    if (loading) return <div>Loading products...</div>;

    return (
        <div className='kollektion'>
            <h1>Kollektion</h1>
            <hr />
            <div className="kollektions">
                {newCollection.map((item, i) => {
                    return (
                        <Item 
                            key={i}
                            id={item.id}
                            name={item.name}
                            image={item.images[0]}  // Access the first image from the images array
                            newPrice={item.new_price} 
                            oldPrice={item.old_price}
                            sizes={item.sizes} 
                            // num={item.productnum}  
                        />
                    );
                })}
            </div>
        </div>
    );
}

export default Kollektion;
