import React, { useEffect, useState } from 'react';
import './Descriptionbox.css';

function DescriptionBox({ productId }) {
  const [productDescription, setProductDescription] = useState('');

  // Function to fetch product details
  const fetchProductDescription = async () => {
    try {
      const response = await fetch(`https://skosiden.dk/backend/index.php?route=allproducts`);
      const data = await response.json();

      console.log('Fetched data:', data); // Log fetched data
      console.log('Fetching description for productId:', productId); // Log productId

      // Find the product by ID and get the description
      const product = data.find((product) => product.id === String(productId)); // Convert productId to string
      if (product) {
        setProductDescription(product.description);
      } else {
        setProductDescription('Description not available.');
      }
    } catch (error) {
      console.error('Error fetching product description:', error);
      setProductDescription('Error fetching description.');
    }
  };

  useEffect(() => {
    fetchProductDescription();
  }, [productId]);

  return (
    <div className='descriptionbox'>
      <div className="descriptionbox-navigator">
        <div className="descriptionbox-nav-box">Description</div>
        <div className="descriptionbox-nav-box fade">Reviews (122)</div>
      </div>
      <div className="descriptionbox-description">
        <p>{productDescription || 'Loading description...'}</p>
      </div>
    </div>
  );
}

export default DescriptionBox;
