import React, { useContext } from 'react'
import './Katagorier.css'
import { ShopContext } from '../Context/ShopContext'
import dropdown_icon from '../Components/Assets/dropdown_icon.png'
import Item from '../Components/Item/Item'
import { Link } from 'react-router-dom';

export const Katagorier = (props) => {
  const {all_product} = useContext(ShopContext)
  return (
    <div className='shop-catagory'>
        <div className="shopcategori-indexSort">
            <p>
                <span>Showing 1-12</span>
            </p>
            <div className="shopcategory-sort">
                Sort by <img src={dropdown_icon} alt="" /> 
            </div>
        </div>
        <div className="shopcategory-products">
            {all_product.map((item,i)=>{
                if (props.category===item.category) {
                    return <Item key={i} id={item.id} name={item.name} image={item.image} new_price={item.new_price} old_price={item.old_price} description={item.description}/>
                }
                else{
                    return null;
                }
            })}
        </div>
        <div className="shopcategory-loadmore">
            Explore More
        </div>
    </div>
  )
}

export default Katagorier;
