import React, { useContext, useState } from "react";
import './Navbar.css';
import logo from '../Assets/logo1.png';
import cart_icon from '../Assets/cart_icon.png';
import { Link } from "react-router-dom";
import { ShopContext } from "../../Context/ShopContext";
const Navbar = () => {

        const[menu,setMenu] = useState("katalog")
        const{getTotalCartItems} = useContext(ShopContext);

    return (
        <div className='navbar'>
            <div className='nav-logo'>
                <Link to='/Katalog'>
                    <img src={logo} alt="" />
                </Link>
                {/* <Link to='/Katalog'>
                <p>Arautorap Slutsalg</p>

                </Link> */}
            </div>
            <ul className="nav-menu">
                {/* <li onClick={()=>{setMenu("katalog")}}><Link to='/'>Katalog</Link>{menu==="katalog"?<hr/>:<></>}</li> */}
                {/* <li onClick={()=>{setMenu("børn")}}><Link to='/Børn'>Børn</Link>{menu==="børn"?<hr/>:<></>}</li> */}
                {/* <li onClick={()=>{setMenu("voksne")}}><Link to='/Voksne'>Voksne</Link>{menu==="voksne"?<hr/>:<></>}</li> */}
            </ul>
            <div className='nav-login-cart'>
                {/* <Link to='/login'><button>Login</button></Link> */}
                <p>Kurv</p>
                <Link to='/cart'><img src={cart_icon} alt=""/></Link>
                <div className="nav-cart-count">{getTotalCartItems()}</div>
            </div>
        </div>
    )
}

export default Navbar;