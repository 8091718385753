// src/Pages/PasswordPage.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from './supabaseClient'; // Import the Supabase client

export const PasswordPage = () => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const logLogin = async (userId) => {
    try {
      // Send POST request to your backend to log the login attempt
      await fetch('https://skosiden.dk/backend/index.php?route=log-login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: userId, // Pass the user ID or other identifying info
        }),
      });
    } catch (error) {
      console.error('Error logging the login:', error);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(''); // Reset error before each login attempt

    try {
      // Authenticate using Supabase
      const { data, error } = await supabase.auth.signInWithPassword({
        email: 'bruger@skosiden.dk',
        password: password,
      });

      // Debugging: Log the full response to see what's returned
      console.log("Supabase login response:", { data, error });

      // Handle errors from Supabase
      if (error) {
        setError('Incorrect password or an error occurred. Please try again.');
      } else if (data && data.user) {
        // Log the login in your database
        logLogin(data.user.id); // Log the user's login (Supabase gives you the user ID)

        // Set sessionStorage for the frontend
        sessionStorage.setItem('authenticated', 'true');

        // Redirect to katalog page
        navigate('/katalog');
      } else {
        // If no user data is returned, show an error
        setError('Unexpected error: No user data received.');
      }
    } catch (error) {
      console.error('Error during login: ', error);
      setError('An unexpected error occurred. Please try again later.');
    }
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '100px' }}>
      <h2>Kodeord:</h2>
      <form onSubmit={handleLogin}>
        <input
          type="password"
          placeholder="Enter Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={{ padding: '10px', fontSize: '16px' }}
          required
        />
        <br />
        <button type="submit" style={{ marginTop: '10px', padding: '10px 20px' }}>
          Fortsæt
        </button>
      </form>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {/* <br /> */}
      {/* <p>Der er desværre problemer med Safari browser i øjeblikket</p> */}
    </div>
  );
};
