// src/Components/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  // Check if the user is authenticated
  const isAuthenticated = sessionStorage.getItem('authenticated');

  if (!isAuthenticated) {
    // If not authenticated, redirect to the password page
    return <Navigate to="/" />;
  }

  // If authenticated, allow access to the route
  return children;
};

export default ProtectedRoute;
