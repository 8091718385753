import React, { useContext } from 'react';
import './CartItems.css';
import { ShopContext } from '../../Context/ShopContext';
import remove_icon from '../Assets/down_arrow.png';
import { useNavigate } from 'react-router-dom'; // Import useNavigate here

const CartItems = () => {
  const { getTotalCartAmount, all_product, cartItems, removeFromCart } = useContext(ShopContext);
  const navigate = useNavigate();

  console.log("Cart Items:", cartItems);
  console.log("Keys in Cart:", Object.keys(cartItems));
  console.log("All Products:", all_product);

  return (
    <div className="cartitems">
      <div className="cartitems-format-main">
        <div>Produkt</div>
        <div>Navn</div>
        <div>Størrelse</div>
        <div>Pris</div>
        <div>Antal</div>
        <div>Total</div>
      </div>
      <hr />

      {Object.keys(cartItems).map((key) => {
        const [productId, selectedSize] = key.split('-'); // Split key to get productId and selectedSize
        const product = all_product.find((prod) => prod.id === String(Number(productId))); // Find product by ID

        if (product && cartItems[key]?.quantity > 0) {
            return (
                <div key={key}>
                    <div className="cartitems-format">
                        <img src={product.images[0]} alt="" className='carticon-product-icon' />
                        <p>{product.name}</p>
                        {/* <p>Product Number: {cartItems[key].productnum}</p> */}

                        <p>{selectedSize}</p>
                        <p>{product.new_price}kr</p>
                        <div className="cartitems-quantity">
                            <button>{cartItems[key].quantity}</button>
                            <img 
                                className='cartitems-remove-icon' 
                                src={remove_icon} 
                                onClick={() => removeFromCart(productId, selectedSize)} // Pass both parameters
                                alt="Remove from cart" 
                            />
                        </div>
                        <p>{(product.new_price * cartItems[key].quantity).toFixed(2)}kr</p>
                    </div>
                    <hr />
                </div>
            );
        }
        return null; // Only return null if product is not found or quantity is zero
    })}


      <div className="cartitems-down">
        <div className="cartitems-total">
          <h1>Kurv</h1>
          <div className="cartitems-total-item">
            <p>Total:</p>
            <p>{getTotalCartAmount()}kr</p>
          </div>
          {/* <div className="cartitems-total-item">
            <p>Levering:</p>
            <p>Du høre fra mig når du indsender formularen på næste side</p>
          </div> */}
          <div className="cartitems-total-item">
            <h3>I alt at betale:</h3>
            <h3>{getTotalCartAmount()}kr</h3>
          </div>
          <button onClick={() => navigate('/Continue', { state: { cartItems, all_product } })}>
            Fortsæt
          </button>
        </div>
      </div>
    </div>
  );
};

export default CartItems;
