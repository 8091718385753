import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Import useNavigate
import { ShopContext } from '../../Context/ShopContext'; // Import ShopContext
import './ProductDisplay.css'; // Import the CSS file

const ProductDisplay = () => {
    const { productId } = useParams(); 
    const [product, setProduct] = useState(null);
    const [error, setError] = useState('');
    const [selectedSize, setSelectedSize] = useState(''); 
    const [mainImage, setMainImage] = useState(null);
    const { addToCart } = useContext(ShopContext); 
    const navigate = useNavigate(); 

    const fetchProduct = async () => {
        try {
            console.log("Fetching product with ID:", productId); // Check the ID
            const response = await fetch(`https://skosiden.dk/backend/?route=product&id=${productId}`);
            const data = await response.json();
            console.log("API Response:", data); // Log the response for debugging
            
            if (data.success) {
                setProduct(data.product);
                setMainImage(data.product.images[0]); // Set the first image as main image
            } else {
                throw new Error(data.message);
            }
        } catch (error) {
            console.error("Error fetching product:", error);
            setError("Failed to fetch product or no product found");
        }
    };

    useEffect(() => {
        fetchProduct();
    }, [productId]); // Fetch product when component mounts or productId changes

    const handleSizeChange = (event) => {
        setSelectedSize(event.target.value);
    };

    const handleImageClick = (image) => {
        setMainImage(image);
    };

    // Add this function to handle adding to the cart
    const handleAddToCart = () => {
        if (selectedSize) {
            addToCart(product.id, selectedSize, product); // Pass the product object here
            navigate('/katalog'); // Redirect to the front page after adding to cart
        } else {
            alert("Please select a size before adding to the cart");
        }
    };

    if (error) {
        return <div>{error}</div>; // Show error message
    }

    if (!product) {
        return <div>Loading...</div>; // Show loading state
    }

    return (
        <div className='productdisplay'>
            <div className="productdisplay-left">
                <div className="productdisplay-img-list">
                    {product.images.map((image, index) => (
                        <img 
                            key={index} 
                            src={image} 
                            alt={`${product.name} ${index + 1}`} 
                            onClick={() => handleImageClick(image)} // Change main image on click
                            style={{ cursor: 'pointer', margin: '5px' }} // Add some styling
                        />
                    ))}
                </div>
            </div>
            <div className="productdisplay-img">
                <img className="productdisplay-main-img" src={mainImage} alt={product.name} />
            </div>
            <div className="productdisplay-right">
                <h1>{product.name}</h1>
                
                <div className="productdisplay-right-description">
                    {product.description}
                </div>

                <div className="productdisplay-right-prices">
                    <p>Pris </p>
                    <div className="productdisplay-right-price-new">{product.new_price}kr</div>
                </div>
                
                <div className="productdisplay-right-size-h">
                    <h1>Vælg Størrelse</h1>
                    <select 
                        value={selectedSize} 
                        onChange={handleSizeChange}
                        className="productdisplay-right-size-dropdown"
                    >
                        <option value="" disabled>Vælg Størrelse</option> {/* Default placeholder option */}
                        {product.sizes && product.sizes.map((sizeObj) => (
                            sizeObj.quantity > 0 ? ( // Only show available sizes
                                <option key={sizeObj.size} value={sizeObj.size}>
                                    {sizeObj.size}
                                </option>
                            ) : (
                                <option key={sizeObj.size} value={sizeObj.size} disabled>
                                    {sizeObj.size} (Udsolgt)
                                </option>
                            )
                        ))}
                    </select>
                </div>

                <button onClick={handleAddToCart}>Føj til kurv</button> {/* Use new add to cart function */}
            </div>
        </div>
    );
};

export default ProductDisplay;
