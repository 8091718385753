import React, { createContext, useEffect, useState } from 'react';

export const ShopContext = createContext(null);

// Function to fetch the default cart from the session
const getSessionCart = async () => {
  try {
    const response = await fetch('https://skosiden.dk/backend/index.php?route=cart'); // Update the URL to your backend
    if (response.ok) {
      const cartData = await response.json();
      return cartData;
    }
  } catch (error) {
    console.error('Error fetching session cart:', error);
  }
  // If there's an error, return an empty cart
  let cart = {};
  for (let index = 0; index < 300 + 1; index++) {
    cart[index] = { quantity: 0, size: '' }; // Store size and quantity
  }
  return cart;
};

const ShopContextProvider = (props) => {
  const [all_product, setAll_Product] = useState([]);
  const [cartItems, setCartItems] = useState({});

  // Fetch all products and initialize cart on mount
  useEffect(() => {
    fetch('https://skosiden.dk/backend/index.php?route=allproducts') // Update the URL to your backend
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // Parse the images field if it's a string
          const productsWithParsedImages = data.products.map(product => ({
            ...product,
            images: JSON.parse(product.images) // Parse the images string to an array
          }));
          setAll_Product(productsWithParsedImages);
        }
      });
  
    // Fetch session cart
    getSessionCart().then((cartData) => setCartItems(cartData));
  }, []);
  

  // Add item to cart
  const addToCart = (productId, selectedSize, product) => {
    const key = `${productId}-${selectedSize}`; // Create a unique key using product ID and selected size

    setCartItems((prev) => ({
        ...prev,
        [key]: {
            quantity: (prev[key]?.quantity || 0) + 1, // Increment quantity
            size: selectedSize, // Store the selected size
            name: product.name, // Include product name
            productNumber: product.productnum, // Include product number
            price: product.new_price // Use new_price from the product object
        },
    }));
};

 
  // Remove item from cart
  const removeFromCart = (productId, selectedSize) => {
    const key = `${productId}-${selectedSize}`; // Create key using productId and size
    setCartItems((prev) => {
        const currentQuantity = prev[key]?.quantity || 0;
        if (currentQuantity > 0) {
            return {
                ...prev,
                [key]: { ...prev[key], quantity: Math.max(currentQuantity - 1, 0) },
            };
        }
        return prev;
    });

    // Assuming you have backend logic to handle this
    fetch('https://skosiden.dk/backend/index.php?route=cart/remove', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ productId, selectedSize }), // Send both productId and selectedSize
    })
    .then((response) => response.json())
    .then((data) => console.log('Item removed from cart:', data))
    .catch((error) => console.error('Error removing from cart:', error));
  };

  // Clear cart function
  const clearCart = () => {
    setCartItems({}); // Clear the cart in state

    // Optionally, clear the cart in the backend session
    fetch('https://skosiden.dk/backend/index.php?route=cart/clear', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.json())
    .then((data) => console.log('Cart cleared:', data))
    .catch((error) => console.error('Error clearing cart:', error));
  };


  // Calculate total cart amount
  const getTotalCartAmount = () => {
  return Object.keys(cartItems).reduce((total, key) => {
    const [productId, selectedSize] = key.split('-');
    const product = all_product.find((prod) => prod.id === String(Number(productId)));
    
    if (product) {
      const itemTotal = product.new_price * cartItems[key].quantity; // Calculate total for the item
      return total + itemTotal; // Accumulate the total
    }
    return total; // If product not found, return total as is
  }, 0).toFixed(2); // Ensure it returns a number formatted to two decimal places
};

  // Calculate total number of items in cart
  const getTotalCartItems = () => {
    let totalItem = 0;
    for (const item in cartItems) {
      totalItem += cartItems[item].quantity;
    }
    return totalItem;
  };

  // Context values
  const contextValue = {
    getTotalCartItems,
    getTotalCartAmount,
    all_product,
    cartItems,
    addToCart,
    removeFromCart,
    clearCart,
  };

  return (
    <ShopContext.Provider value={contextValue}>
      {props.children}
    </ShopContext.Provider>
  );
};

export default ShopContextProvider;
