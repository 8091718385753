import React, { useState } from 'react'
import './LoginSignup.css'
import { Link } from 'react-router-dom';

export const LoginSignup = () => {

  const [state,setState] = useState("Login");
  const [formData, setFormData] = useState({
    username:"",
    password:"",
    email:""
  })

  const changeHandler = (e) => {
    setFormData({...formData,[e.target.name]:e.target.value})
  }

  const login = async () => {
    console.log("login funktion", formData);
    let responseData;
    await fetch('http://localhost:4000/login',{
      method:'POST',
      headers:{
        Accept:'application/form-data',
        'Content-Type' : 'application/json' 
      },
      body: JSON.stringify(formData)
    }).then((response)=> response.json()).then((data)=> responseData=data)
    
    if(responseData.success){
      localStorage.setItem('auth-token',responseData.token);
      window.location.replace("/");
    }
    else{
      alert(responseData.errors)
    }
  }

  const signup = async () => {
    console.log("signup funktion", formData);  
    let responseData;
    await fetch('http://localhost:4000/signup',{
      method:'POST',
      headers:{
        Accept:'application/form-data',
        'Content-Type' : 'application/json' 
      },
      body: JSON.stringify(formData)
    }).then((response)=> response.json()).then((data)=> responseData=data)
    
    if(responseData.success){
      localStorage.setItem('auth-token',responseData.token);
      window.location.replace("/");
    }
    else{
      alert(responseData.errors)
    }
  }

  return ( 
    <div className='loginsignup'>
        <div className="loginsignup-container">
            <h1>{state}</h1>
            <div className='loginsignup-fields'>
                {state==="SignUp"?<input name='username' value={formData.username} onChange={changeHandler} type='text' placeholder='Name' />:<></>}
                <input name='email' value={formData.email} onChange={changeHandler} type='email' placeholder='email' />
                <input name='password' value={formData.password} onChange={changeHandler} type='password' placeholder='password' />
            </div>
            <button onClick={()=>{state==="Login"?login():signup()}}>Fortsæt</button>
            {state==="SignUp"
            ?<p className='loginsignup-login'>Login <span onClick={()=>{setState("Login")}}> her </span></p>
            :<p className='loginsignup-login'>Tilmeld <span onClick={()=>{setState("SignUp")}}> her </span></p>}
           
            <div className="loginsignup-agree">
                <input type='checkbox' name='' id= '' />
            </div>
        </div>
    </div>
  )
}
