import React from 'react'
import Popular from '../Components/Popular/Popular'
import Kollektion from '../Components/Kollektion/Kollektion'
import Footer from '../Components/Footer/Footer'
import { Link } from 'react-router-dom';

export const Katalog = () => {
  return (
    <div>
        {/* <Popular/> */}
        <Kollektion />
        
    </div>

  )
}
