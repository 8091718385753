import React from 'react';
import './Item.css';
import { Link } from 'react-router-dom';

export const Item = (props) => {
  return (
    <div className='item'>
      {/* Link to product detail page using the product ID */}
      <Link to={`/product/${props.id}`} onClick={() => window.scrollTo(0, 0)}>
        <img src={props.image} alt={props.name} />
      </Link>
      <p>{props.name}</p>
      {/* Display available sizes */}
      <div className="sizes">
        <h3>Størrelser:</h3>
        <p>{props.sizes.map(sizeObj => sizeObj.size).join(', ')}</p>  {/* Join sizes with commas */}
      </div>
      <div className="item-prices">
        <div className="item-price-new">{props.newPrice} kr</div>
        {/* Uncomment if you want to display the old price */}
        {/* <div className="item-price-old">{props.oldPrice} kr</div> */}
      </div>
    </div>
  );
}

export default Item;
