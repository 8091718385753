import './App.css';
import Navbar from './Components/Navbar/Navbar';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useEffect } from 'react';
import { Katalog } from './Pages/Katalog';
import { Katagorier } from './Pages/Katagorier';
import Product from './Pages/Product';
import { Cart } from './Pages/Cart';
import { LoginSignup } from './Pages/LoginSignup';
import { PasswordPage } from './Pages/PasswordPage';
import ProtectedRoute from './Components/ProtectedRoute/ProtectedRoute'; // Import the ProtectedRoute component
import { Continue } from './Components/CartItems/Continue';

function App() {
  useEffect(() => {
    clearCookies();
  }, []);

  const clearCookies = () => {
    const cookies = document.cookie.split("; ");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      // Clear each cookie by setting it with a past expiration date
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;";
    }
  };
  return (
    <div>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<PasswordPage />} /> {/* Password page as the default route */}

          {/* Wrap all protected routes with ProtectedRoute */}
          <Route
            path="/katalog"
            element={
              <ProtectedRoute>
                <Katalog />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Børn"
            element={
              <ProtectedRoute>
                <Katagorier katagori="Børn" />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Voksne"
            element={
              <ProtectedRoute>
                <Katagorier katagori="Voksne" />
              </ProtectedRoute>
            }
          />
          <Route
            path="/product/:productId"
            element={
              <ProtectedRoute>
                <Product />
              </ProtectedRoute>
            }
          />
          <Route
            path="/cart"
            element={
              <ProtectedRoute>
                <Cart />
              </ProtectedRoute>
            }
          />
          <Route
            path="/continue"
            element={
              <ProtectedRoute>
                <Continue />
              </ProtectedRoute>
            }
          />
          <Route
            path="/login"
            element={
              <ProtectedRoute>
                <LoginSignup />
              </ProtectedRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
