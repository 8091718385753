import React from 'react'
import './Breadcrum.css'
import arrow_icon from '../Assets/arrow.png'
import { Link } from 'react-router-dom'

const Breadcrum = (props) => {
    const {product} = props;
  return (
    <div className='breadcrum'>
    <Link to="/katalog" className="breadcrum-link">Tilbage</Link>
    {/* Hjem <img src={arrow_icon} alt="" />{product.category} <img src={arrow_icon} alt="" /> {product.name} */}
    </div>
  )
}

export default Breadcrum