import React, { useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ShopContext } from '../../Context/ShopContext';
import './Continue.css';

export const Continue = () => {
  const { clearCart } = useContext(ShopContext);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
  });
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { cartItems, all_product } = location.state;

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
      e.preventDefault(); // Prevent the default form submission

      // Form validation
      if (!formData.name || !formData.email || !formData.phone) {
          setError('All fields are required.');
          return;
      }

      // Create cart data from cartItems
      const cartData = Object.keys(cartItems).map((key) => {
          const [productId, selectedSize] = key.split('-');
          const product = all_product.find((product) => String(product.id) === productId);
          
          if (product && cartItems[key]?.quantity > 0) {
              return {
                  productId: product.id,
                  name: product.name,
                  size: selectedSize,
                  quantity: cartItems[key].quantity,
                  price: product.new_price,
              };
          }
          return null;
      }).filter(Boolean); // Filter out null values

      const submissionData = {
          ...formData,
          cart: cartData,
      };

      try {
          // Submitting order
          const response = await fetch('https://skosiden.dk/backend/index.php?route=submit-form', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(submissionData),
          });

          console.log('Response:', response);
          const data = await response.json();
          console.log('Response Data:', data);

          if (response.ok) {
              clearCart(); // Clear the cart after successful submission
              navigate('/katalog'); // Redirect to catalog
          } else {
              setError(data.message || 'Failed to submit the form.');
          }
      } catch (err) {
          setError('Error submitting form.');
          console.error(err);
      }
  };



  return (
    <div className='continue-container'>
      <h2>Informationer</h2>
      <div className='form-cart-container'>
        <form onSubmit={handleSubmit} className='form'>
          <div>
            <label>Navn:</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Navn"
              className='form-input'
            />
          </div>
          <div>
            <label>Email:</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email"
              className='form-input'
            />
          </div>
          <div>
            <label>Telefon:</label>
            <input
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="Telefonnummer"
              className='form-input'
            />
          </div>
          {error && <p className='error'>{error}</p>}
          <button type="submit" className='submit-button'>Indsend</button>
        </form>

        {/* Display Cart Items */}
        <div className="kurv">
          <h2>Din Kurv</h2>
          <div>
            {Object.keys(cartItems).map((key) => {
              const [productId, selectedSize] = key.split('-');
              const product = all_product.find((product) => String(product.id) === productId);

              if (product && cartItems[key]?.quantity > 0) {
                return (
                  <div key={key} className='cart-item'>
                    <div className="cart-item-info">
                      <div>
                        {/* <p>Nummer: {product.productnum}</p> */}
                        <p>Navn: {product.name}</p>
                        <p>Størrelse: {selectedSize}</p>
                        <p>Antal: {cartItems[key].quantity}</p>
                        <p>Pris: {product.new_price} kr</p>
                        <p>Total: {(product.new_price * cartItems[key].quantity).toFixed(2)} kr</p>
                        <p>+ Levering, du hører fra mig når du har lavet ordren</p>
                      </div>
                      <img src={product.images[0]} alt={product.name} className='cart-item-image' />
                    </div>
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
